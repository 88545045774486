import request from '../../util/request';
import { CRM, CLUE, BASIC } from '../apiConst';

export default {
  // //获取侧边栏项目list
  // saveOwnerCard(data) {
  //   return request.post({url: `${CRM}/crm/mobile/v1/owner-card/save`, data});
  // },
  //获取当前登录用户拥有权限的项目集合(根据运营主体数据权限过滤)
  getSidebarEstates(params, hideLoading = false) {
    return request.get({ url: `${BASIC}/estate-sale/v1/sale-op-estates`, params, headers: { appPermit: 'potential' }, hideLoading });
  },
  //判断侧边栏归属-clue
  sidebarBelongClue(params, hideLoading = false) {
    return request.get({ url: `${CLUE}/clue/sidebar/v1/sidebar-clue-belong`, params, hideLoading });
  },
  //判断侧边栏归属
  sidebarBelong(params, hideLoading = false) {
    return request.get({ url: `${CRM}/crm/sidebar/v1/sidebar-cus-belong`, params, hideLoading });
  },

  //话术

  //话术类别列表
  getCategoryList(params) {
    return request.get({ url: `${CLUE}/mar/speech-craft/v1/webapp/category-list`, params });
  },
  //查询移动端话术
  getCraftList(data) {
    return request.post({ url: `${CLUE}/mar/speech-craft/v1/webapp/craft-page`, data });
  },
  //校验话术是否有效
  mediaIdVali(data) {
    return request.post({ url: `${CLUE}/mar/speech-craft/v1/webapp/check-craft-valid`, data });
  }
};
