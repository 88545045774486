<template>
  <div class="abnormal">
    <van-cell
        v-if="!onePro&&$route.query.code=='-1000'"
        @click="show=true"
        size="large"
        is-link
        center
    >
      <template #title>
        <span class="custom-title">项目：</span>
        <span class="pro-name">{{ name }}</span>
      </template>
    </van-cell>
    <img
        src="@/assets/images/noAuth.png"
        alt=""
    >
    <div class="describe">
      {{ text }}
    </div>

    <!--    项目弹窗-->
    <van-action-sheet
        v-model="show"
        :actions="actions"
        cancel-text="取消"
        close-on-click-action
        get-container="#app"
        @select="selectProj"
    />
  </div>
</template>

<script>
import sidebar from '@/api/sidebar';
import login from '@/api/login';

export default {
  name: 'abnormal',
  data () {
    return {
      text: '',
      onePro: true,
      show: false,
      actions: [],
      opId: '',
    };
  },
  methods: {
    //获取项目
    async getSidebarEstates () {
      let { code, data } = await sidebar.getSidebarEstates({ opId: this.opId });
      if (code === '0') {
        if (data.length >= 2) {
          this.onePro = false;
        }
        this.actions = data.map(item => {
          item.name = item.estateShowName;
          if (item.estateId === localStorage.getItem('platform_sidebarEstateId')) {
            item.color = '#3A74F2';
            this.name = item.estateShowName;
          } else {
            item.color = '';
          }
          return item;
        });
      }
    },
    //选择项目
    selectProj (val) {
      localStorage.setItem('platform_sidebarEstateId', val.estateId);
      this.name = val.estateShowName;
      this.actions = this.actions.map(item => {
        if (item.estateId === val.estateId) {
          item.color = '#3A74F2';
        } else {
          item.color = '';
        }
        return item;
      });
      this.judgeAppPermit();
    },
    //侧边栏流程 跳转应用逻辑
    async judgeAppPermit () {
      //获取应用权限
      let res = await login.appAuthority({ estateId: localStorage.getItem('platform_sidebarEstateId') });
      if (res.code === '0') {
        let appPermit = res.data.map((item) => item.permit);
        if (appPermit.some(item => item === 'potential')) {
          let params = {
            qwExternalUserid: localStorage.getItem('platform_qwExternalUserid'),
            estateId: localStorage.getItem('platform_sidebarEstateId'),
            opId: localStorage.getItem('platform_opId')
          };
          //先判断潜客归属,再看是否跳转scrm;
          let { code, data } = await sidebar.sidebarBelongClue(params);
          if (code === '0') {
            if (data.hasConverted === 1) {
              await this.jumpScrm(appPermit, data);
            } else if (data.hasConverted === 0) {
              await this.jumpPotential(data);
            }
          }
        } else {
          let jumpHost = `${window.location.protocol}//` + location.host;
          window.location.href = jumpHost + `/#/abnormal?code=${-1000}&msg=当前项目未启用潜客宝`;
        }
      }
    },

    //跳转scrm详情
    async jumpScrm (appPermit, data) {
      this.toCloseWindow = false;
      if (appPermit.some(item => item === 'scrm')) {
        let val = await sidebar.sidebarBelong({
          estateId: localStorage.getItem('platform_sidebarEstateId'),
          clueId: data.clueId,
          opId: localStorage.getItem('platform_opId'),
        });
        //判断用户归属
        if (val.code === '0') {
          let jumpHost = `${window.location.protocol}//` + location.host;
          switch (val.data.judgeFlag) {
            case 1:
              window.location.href = jumpHost + '/crm/#/sidebar/turnClient';
              break;
            case 2:
              window.location.href = jumpHost + '/crm/#/sidebar/noClient';
              break;
            case 3:
              window.location.href = jumpHost + `/crm/#/sidebar/detail?oppId=${val.data.oppId}`;
              break;
            case 4:
              window.location.href = jumpHost + '/crm/#/sidebar/turnClient';
              break;
            default:
              break;
          }
        }
      } else {
        let jumpHost = `${window.location.protocol}//` + location.host;
        window.location.href = jumpHost + `/#/abnormal?code=${-1000}&msg=该好友已转化为客户，当前项目未启用CRM`;
      }
    },
    //跳转潜客详情
    async jumpPotential (data) {
      this.toCloseWindow = false;
      let jumpHost = `${window.location.protocol}//` + location.host;
      switch (data.clueBelongFlag) {
        case 0:
          window.location.href = jumpHost + '/potential/#/sidebar/noClient';
          break;
        case 1:
          window.location.href = jumpHost + `/potential/#/sidebar/detail?clueId=${data.clueId}`;
          break;
        default:
          break;
      }
    },
  },
  mounted () {
    this.opId = localStorage.getItem('platform_opId');
    if (this.$route.query.code && this.$route.query.msg) {
      this.text = this.$route.query.msg;
    }
    if (this.$route.query.code === '-1000') {
      this.getSidebarEstates();
    }
  },
  beforeRouteLeave (to, from, next) {
    if (to.path == '/') { //必要的
      this.$wx.closeWindow();
    } else {
      next();
    }
  },
};
</script>

<style lang="less" scoped>
.abnormal {
  height: 100vh;
  background-color: @gary-white;

  img {
    padding: 40px 64px 0px 64px;
    width: 240px;
    height: 148px;
  }

  .describe {
    width: 311px;
    line-height: 24px;
    margin: 8px auto auto;
    color: #303133;
    font-weight: bold;
    font-size: 17px;
    text-align: center;
  }
}
</style>
